import { graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import CampaignCard from "../components/campaignCard";
import ContactUsSection from "../components/contactUs";
import { Layout } from "../components/layout";
import Picture from "../components/picture";
import styles from "./index.module.scss";

export default function Landing({
  data: {
    allContentfulCampaign,
    allContentfulLandingPageSeoContent,
    contentfulLandingPage,
  },
}) {
  const PUBLIC_URL = process.env.GATSBY_PUBLIC_URL;
  const landingPageSEOContent =
    allContentfulLandingPageSeoContent.edges[0].node;
  return (
    <>
      <Helmet>
        <title>{landingPageSEOContent.title}</title>
        <meta name="description" content={landingPageSEOContent.description} />
        <meta property="og:type" name="og:type" content="website" />
        <meta
          property="og:title"
          name="og:title"
          content={landingPageSEOContent.title}
        />
        <meta property="og:url" name="og:url" content={PUBLIC_URL} />
        <meta
          property="og:description"
          name="og:description"
          content={landingPageSEOContent.description}
        />
        <meta
          property="og:image"
          name="og:image"
          content={`${PUBLIC_URL}/icons/192x192.png`}
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content="@metta_social" />
        <meta name="twitter:title" content={landingPageSEOContent.title} />
        <meta
          name="twitter:description"
          content={landingPageSEOContent.description}
        />
        <meta
          name="twitter:image"
          content={`${PUBLIC_URL}/icons/192x192.png`}
        />
      </Helmet>
      <Layout>
        <div className={styles.banner}>
          <Picture
            image={contentfulLandingPage.banner.image}
            className="abs-full h-100 w-100"
            height={100}
          />
          <div className="abs-full mx-auto">
            <div className="col-12 container justify-content-center text-white h-100 w-100 d-flex flex-column">
              <h1 className="font-weight-bolder text-shadow-sm">
                {contentfulLandingPage.banner.title}
              </h1>
              <p className="lead text-shadow-sm">
                {contentfulLandingPage.banner.description}
              </p>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className={`row align-items-stretch`}>
            {allContentfulCampaign.edges.map(campaign => {
              return <CampaignCard campaign={campaign} key={campaign.slug} />;
            })}
          </div>
          <ContactUsSection />
        </div>
      </Layout>
    </>
  );
}

export const query = graphql`
  query {
    contentfulLandingPage {
      banner {
        image {
          fluid {
            srcSet
            src
            sizes
            srcSetWebp
            srcWebp
            aspectRatio
          }
          file {
            url
          }
          title
        }
        title
        description
      }
    }
    allContentfulLandingPageSeoContent {
      edges {
        node {
          description
          title
        }
      }
    }
    allContentfulCampaign(sort: { fields: sequenceNumber, order: ASC }) {
      edges {
        node {
          title
          slug
          campaignStartDate
          campaignEndDate
          ngo {
            ngoName
            ngoImpactArea
            logo {
              fluid {
                srcWebp
                srcSetWebp
                srcSet
                src
                sizes
                aspectRatio
              }
              file {
                url
              }
            }
          }
          goal
          goalCurrency
          banner {
            sequenceno
            image {
              fluid {
                srcWebp
                srcSetWebp
                srcSet
                src
                sizes
                aspectRatio
              }
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`;
