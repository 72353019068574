import { Link } from "gatsby";
import React, { useLayoutEffect } from "react";
import facebookIcon from "../assets/facebook-color.svg";
import supportersIcon from "../assets/icn-supporters.png";
import linkedInIcon from "../assets/linkedin-color.svg";
import twitterIcon from "../assets/twitter-color.svg";
import whatsappIcon from "../assets/whatsapp-color.svg";
import AchievedIcon from "../assets/icn-achieved.svg";
import { useGetFundRaisedByCampaign } from "../helpers/hooks";
import { currencyFormatterOptions } from "../helpers/constants";
import Picture from "./picture";
export default function CampaignCard({ campaign }) {
  const {
    slug,
    goal,
    banner,
    goalCurrency,
    ngo,
    title,
    campaignEndDate,
  } = campaign.node;
  const campaignSlug = `/campaign/${slug}`;
  const campaignAbsoluteURL = `${process.env.GATSBY_PUBLIC_URL}${campaignSlug}`;
  const shareIconSize = 25;
  const { data: { fundRaised, donorsCount } = {} } = useGetFundRaisedByCampaign(
    slug
  );
  const currdate = new Date();
  const isFundsRaisedNotFetched = fundRaised === undefined;
  const campaignCondition =
    campaignEndDate &&
    campaignEndDate?.toString().split("T")[0] <
      currdate.toISOString().toString().split("T")[0]
      ? "true"
      : "false";
  useLayoutEffect(() => {
    window.Sharer.init();
  }, []);
  const currencyFormatter = new Intl.NumberFormat("en-IN", {
    ...currencyFormatterOptions,
    currency: goalCurrency,
  });
  return (
    <article
      className={`p-0 m-0 col-12 col-sm-6 col-xl-4 row perspective-anim `}
    >
      <div
        className={`p-0 rounded-t shadow card col-12 scale-092  ${
          goal <= fundRaised || campaignCondition === "true"
            ? "campaignCardDisable"
            : ""
        }`}
      >
        <Link to={campaignSlug} exact>
          <div className="rounded-t img-5x3 position-relative loading-bg">
            <Picture
              className="rounded-t abs-full"
              image={banner[0].image}
            ></Picture>
            <small className="p-2 d-inline-block abs-bottom-right bg-dark text-light rounded-cross-nw">
              {ngo.ngoImpactArea}
            </small>
          </div>
        </Link>
        <div className="card-body row mx-0 align-content-between">
          <header className="row">
            <Link
              to={campaignSlug}
              className="col-12 text-dark text-decoration-none"
              exact
            >
              <h4 className="w-100 h3 card-title text-gradient text-shadow-sm">
                {title}
              </h4>
              <div className="row align-items-center">
                <div className="col-auto">
                  <div className="img-64x64 position-relative">
                    <Picture image={ngo.logo} className="abs-full"></Picture>
                  </div>
                </div>
                <div className="col-auto pl-0">
                  <h5 className="h6">
                    <span className="text-info">By</span>{" "}
                    <span className="text-gradient invert-gradient">
                      {ngo.ngoName}
                    </span>
                  </h5>
                  <div className="d-flex align-items-center">
                    <img
                      src={supportersIcon}
                      alt=""
                      height={16}
                      width={24}
                      className="mr-2"
                    />
                    <p className="h6 mb-0 text-muted">
                      {donorsCount === undefined ? <>&mdash;</> : donorsCount}{" "}
                      Supporters
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </header>
          <hr className="col-11" />
          <dl className="row lead w-100 mx-auto">
            <dt className="col-12 px-0 text-gradient invert-gradient d-inline-flex justify-content-between">
              <span className="text-gradient invert-gradient">
                {isFundsRaisedNotFetched ? (
                  <>&mdash;</>
                ) : (
                  currencyFormatter.format(fundRaised)
                )}{" "}
                Raised
              </span>
              <span className="text-gradient invert-gradient">
                {isFundsRaisedNotFetched ? (
                  <>&mdash;</>
                ) : (
                  ((fundRaised / goal) * 100).toFixed(1) + "%"
                )}
              </span>
            </dt>
            <dd className="col-12 px-0 mx-auto">
              <div className="progress rounded-m">
                <div
                  className="progress-bar bg-gradient rounded-m"
                  style={{
                    width: isFundsRaisedNotFetched
                      ? 0
                      : `${(fundRaised / goal) * 100}%`,
                  }}
                ></div>
              </div>
            </dd>
          </dl>
          {campaignCondition === "true" ? (
            <div className="row align-items-center">
              <div className="col-auto">
                <div className="img-64x64 position-relative">
                  <img
                    src={AchievedIcon}
                    alt=""
                    height={16}
                    width={24}
                    className="mr-2"
                  />
                </div>
              </div>
              <div className="col-auto pl-0">
                <h5 className="h7">
                  <span className="text-info font-16 mb-0 text-muted">
                    Donations Closed
                  </span>{" "}
                </h5>
                <div className="d-flex align-items-center">
                  <p className="h6 mb-0 font-14 text-muted">
                    The campaign has reached its end date.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {goal <= fundRaised ? (
            <div className="row align-items-center">
              <div className="col-auto">
                <div className="img-64x64 position-relative">
                  <img
                    src={AchievedIcon}
                    alt=""
                    height={16}
                    width={24}
                    className="mr-2"
                  />
                </div>
              </div>
              <div className="col-auto pl-0">
                <h5 className="h7">
                  <span className="text-info font-16 mb-0 text-muted">
                    Donations Closed
                  </span>{" "}
                </h5>
                <div className="d-flex align-items-center">
                  <p className="h6 mb-0 font-14 text-muted">
                    The goal for this has been achieved.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <dl className="mb-0 mx-auto text-capitalize row w-100">
              <div className="col-6 pl-0">
                <dt className="font-weight-normal text-black-50">Goal</dt>
                <dd>
                  <span className="mr-4 font-weight-normal">
                    {currencyFormatter.format(goal)}
                  </span>
                </dd>
              </div>
              <div className="col-6 pr-0">
                <dt className="font-weight-normal text-black-50">Remaining</dt>
                <span className="font-weight-normal">
                  {isFundsRaisedNotFetched ? (
                    <>&mdash;</>
                  ) : (
                    currencyFormatter.format(goal - fundRaised)
                  )}
                </span>
              </div>
            </dl>
          )}{" "}
        </div>
        <div className="card-footer">
          <div className="row align-items-center justify-content-between">
            <p className="mb-0 col-4">Share via:</p>
            <div className="col-8 text-right">
              <button
                className="btn btn-link px-2 mx-2 btn-lg text-black shadow-sm"
                aria-label="Share via facebook"
                data-sharer="facebook"
                title="Share via Facebook"
                data-url={campaignAbsoluteURL}
              >
                <img
                  alt=""
                  aria-hidden="true"
                  src={facebookIcon}
                  height={shareIconSize}
                  width={shareIconSize}
                />
              </button>
              <button
                className="btn btn-link px-2 mx-2 btn-lg text-black shadow-sm"
                aria-label="Share via twitter"
                data-sharer="twitter"
                title="Share via Twitter"
                data-title={`Help by donating to ${title}`}
                data-url={campaignAbsoluteURL}
              >
                <img
                  alt=""
                  aria-hidden="true"
                  src={twitterIcon}
                  height={shareIconSize}
                  width={shareIconSize}
                />
              </button>
              <button
                className="btn btn-link px-2 mx-2 btn-lg text-black shadow-sm d-sm-none d-md-inline"
                aria-label="Share via linkedin"
                data-sharer="linkedin"
                title="Share via Linkedin"
                data-url={campaignAbsoluteURL}
              >
                <img
                  alt=""
                  aria-hidden="true"
                  src={linkedInIcon}
                  height={shareIconSize}
                  width={shareIconSize}
                />
              </button>
              <button
                className="btn btn-link px-2 ml-2 btn-lg text-black shadow-sm"
                aria-label="Share via whatsapp"
                data-sharer="whatsapp"
                title="Share via Whatsapp"
                data-title={`Help by donating to ${title}`}
                data-url={campaignAbsoluteURL}
              >
                <img
                  alt="w"
                  aria-hidden="true"
                  src={whatsappIcon}
                  height={shareIconSize}
                  width={shareIconSize}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}
